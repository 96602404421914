import React, { Component } from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import ProgressBar from "../components/layouts/progressbar"
import HeadMeta from "../components/layouts/headmeta"
import API from "../services/api"

class Item extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      lineItem: [],
      additionalAddonTraits: [],
    }
  }
  componentDidMount() {
    var self = this
    let { cart_id } = this.props
    API.getCart(cart_id).then(function(response) {
      console.log(response.data)
      self.setState({
        name:
          response.data.user.first_name + " " + response.data.user.last_name,
        email: response.data.user.email,
        lineItem: response.data.line_items,
        additionalAddonTraits: response.data.additional_addon_traits,
      })
    })
  }
  render() {
    let { name, lineItem, additionalAddonTraits, email } = this.state
    let { i } = this.props
    return (
      <div className="text-left" key={i}>
        <label>
          {i + 1}. {name} ({email})
        </label>
        <ul>
          {Object.keys(lineItem).map((key, i) => (
            <li key={key}>
              {lineItem[i].package.name} (RM
              {lineItem[i].package.price_cents / 100})
            </li>
          ))}
          {Object.keys(additionalAddonTraits).map((key, i) => (
            <li key={key}>
              {additionalAddonTraits[i].product.name} (RM
              {additionalAddonTraits[i].product.price / 100})
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default class CartOverview extends Component {
  constructor(props) {
    super(props)
    this.state = { orderTotal: 0, carts: [] }
  }

  componentDidMount() {
    window.analytics.page("Cart", "Overview")
    this.setState({
      orderTotal: localStorage.getItem("cart_total_price"),
      carts: JSON.parse(localStorage.getItem("advanx_cart_id")),
    })
  }

  render() {
    const { metadata } = pageData
    let { orderTotal, carts } = this.state

    return (
      <Layout>
        <HeadMeta metadata={metadata} />
        <ProgressBar />
        <section
          className="hero hero-landing bg-gradient-blue-green"
          style={{ minHeight: "50vh" }}
        >
          <div className="container text-white">
            <header className="text-center mb-4">
              <h1>Shopping Cart</h1>
              <p className="lead">Cart Overview.</p>
            </header>
          </div>
        </section>
        <section>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="card-title text-right">
                      <small>
                        Cart total: <h5>RM {orderTotal}</h5>
                      </small>
                    </div>
                    <h5>Order summary</h5>
                    <div className="text-left pt-3">
                      {Object.keys(carts).map((key, i) => (
                        <Item i={i} cart_id={carts[key]} />
                      ))}
                    </div>
                    <div className="pt-5">
                      <a
                        href={
                          "https://app.advanxhealth.com/checkout/" +
                          carts[0] +
                          "/payments/new"
                        }
                        class="btn btn-primary"
                      >
                        Proceed to Checkout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Cart Overview | Advanx Health",
    description:
      "Discover your DNA with comprehensive DNA tests and get personalised recommendations for health risks, nutrition traits, fitness traits and more!",
    image: "https://www.advanxhealth.com/img/share/power-of-genes.png",
    url: "https://www.advanxhealth.com/cart-overview",
  },
}
